import { Model } from "vue-mc";


class Privilege extends Model {
  options() {
    return {
      editable: true,
    };
  }

  defaults() {
    return {
      status: null,
      errorCode: null,
      errorMessage: null,
      privilegeId: 0,
      privilegeName: "",
      privilegeCode: "",
      displayToUser: false,
      tag: "",
      mandatory: "N",
      hasValue: "N",
      priceMonthly: 0,
      priceQuarterly: 0,
      priceAnnually: 0,
      priceValueMultiplierFunction: null,
      createdAutomatically: "N",
      privilegeChilds: null,
      privilegeChildObjects: null,
      promoCode: null,
      priceWithPromo: null,
      priceWithPromoEndDate: null,
      privilegeValue: null,
      paid: null,
      createdAutomatically: "N",
    };
  }

  mutations() {
    return {
      privilegeId: Number,
      privilegeName: String,
      privilegeCode: String,
      displayToUser: Boolean,
      tag: String,
      mandatory: String,
      hasValue: String,
      priceMonthly: Number,
      priceQuarterly: Number,
      priceAnnually: Number,
      priceValueMultiplierFunction: Number,
      createdAutomatically: String,
      //privilegeChilds: Array,
    };
  }

  validation() {
    return;
    {
    }
  }
}

export default Privilege;
